import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import {
  FaProductHunt,
  FaTeamspeak,
  FaHome,
  FaRegListAlt,
  FaComments,
  FaAddressBook,
  FaNewspaper,
  FaGlobe,
  FaHandPaper
} from "react-icons/fa";
import vgicon from "./Palmis-leaf.png";

const Header = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const drawerRef = useRef(null);
  const location = useLocation(); // Use location to detect route changes

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      closeDrawer();
    }
  };

  const handleEscKey = (event) => {
    if (event.key === "Escape") {
      closeDrawer();
    }
  };

  useEffect(() => {
    if (isDrawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleEscKey);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [isDrawerOpen]);

  // Automatically close the drawer on route change
  useEffect(() => {
    closeDrawer();
  }, [location.pathname]);

  return (
    <header
      className={`fixed top-0 left-0 w-full z-50 py-1 transition-colors duration-300 ${
        isScrolled ? "bg-[#061630] shadow-md" : "bg-transparent"
      }`}
    >
      <div className="flex justify-between items-center">
        <div className="ml-4">
          <button onClick={toggleDrawer} className="text-amber-200 hover:text-gray-200">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>

        <div className="flex justify-center items-center space-x-2 sm:space-x-4">
          <img src={vgicon} alt="palmis global logo" className="w-12 h-14 sm:w-16 sm:h-16 md:w-16 md:h-20" />
          <Link to="/" className="text-xl font-bold text-amber-200 sm:text-2xl md:text-3xl">
            PALMIS GLOBAL
          </Link>
        </div>

        <div className="mr-4">
          <button onClick={toggleSearch} className="text-green-700 hover:text-gray-200">
            {/* Search Icon */}
          </button>
          {isSearchOpen && (
            <div className="absolute top-0 right-12 mt-12 mr-4">
              <input
                type="text"
                placeholder="Search..."
                className="px-4 py-2 rounded-md border-gray-600 border focus:outline-none focus:ring focus:ring-gray-400"
              />
            </div>
          )}
        </div>
      </div>

      <div
        ref={drawerRef}
        className={`fixed left-0 top-0 h-screen w-64 sm:w-80 ${
          isDrawerOpen ? "" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out bg-[#061630]`}
      >
        <div className="p-4">
          <div className="flex justify-between items-center mb-8">
            <Link to="/" className="text-2xl sm:text-3xl font-semibold text-amber-200">
              PALMIS GLOBAL
            </Link>
            <button onClick={closeDrawer} className="text-amber-200 hover:text-red-700">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <ul>
            <li className="mb-4">
              <Link to="/" className="flex items-center text-xl sm:text-2xl text-amber-200 hover:text-amber-600" onClick={closeDrawer}>
                <FaHome className="mr-2" /> Home
              </Link>
            </li>
            <li className="mb-4">
              <Link to="/products" className="flex items-center text-xl sm:text-2xl text-amber-200 hover:text-amber-600" onClick={closeDrawer}>
                <FaProductHunt className="mr-2" /> Products
              </Link>
            </li>
            <li className="mb-4">
              <Link to="/teams" className="flex items-center text-xl sm:text-2xl text-amber-200 hover:text-amber-600" onClick={closeDrawer}>
                <FaTeamspeak className="mr-2" /> Team
              </Link>
            </li>
            <li className="mb-4">
              <Link to="/career" className="flex items-center text-xl sm:text-2xl text-amber-200 hover:text-amber-600" onClick={closeDrawer}>
                <FaRegListAlt className="mr-2" /> Career
              </Link>
            </li>
            <li className="mb-4">
              <Link to="/corporategovernance" className="flex items-center text-xl sm:text-2xl text-amber-200 hover:text-amber-600" onClick={closeDrawer}>
                <FaGlobe className="mr-2" /> Corporate Governance
              </Link>
            </li>
            <li className="mb-4">
              <Link to="/partnership" className="flex items-center text-xl sm:text-2xl text-amber-200 hover:text-amber-600" onClick={closeDrawer}>
                <FaHandPaper className="mr-2" /> Partnerships
              </Link>
            </li>
            <li className="mb-4">
              <Link to="/contact" className="flex items-center text-xl sm:text-2xl text-amber-200 hover:text-amber-600" onClick={closeDrawer}>
                <FaComments className="mr-2" /> Contact
              </Link>
            </li>

            <li className="mb-4">
              <Link to="/about" className="flex items-center text-xl sm:text-2xl text-amber-200 hover:text-amber-600" onClick={closeDrawer}>
                <FaAddressBook className="mr-2" /> About Us
              </Link>
            </li>

            <li className="mb-4">
              <Link to="/news" className="flex items-center text-xl sm:text-2xl text-amber-200 hover:text-amber-600" onClick={closeDrawer}>
                <FaNewspaper className="mr-2" /> News
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
