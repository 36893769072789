import React from 'react';
import { useNavigate } from 'react-router-dom';

const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <header className="relative flex flex-col h-screen overflow-hidden mb-12">
      {/* Video Background */}
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute top-0 left-0 w-full h-auto min-w-full min-h-full max-w-none max-h-none object-cover z-10"
      >
        <source
          src="https://cdn.pixabay.com/video/2024/08/15/226561.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>

      {/* Overlay */}
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-20"></div>

      {/* Content Wrapper */}
      <div className="relative z-30 flex flex-col items-center justify-center w-full h-full px-4 md:px-12">
        {/* Text Content */}
        <div className="flex flex-col items-center justify-center text-center mb-8 space-y-4">
          <div className="text-3xl md:text-4xl lg:text-5xl font-bold text-white bg-opacity-50 rounded-xl mb-4 px-4 py-2">
            Palmis Global 
          </div>
          <div className="text-base md:text-lg lg:text-xl text-gray-300 mb-4 px-4">
            Empowering businesses with cutting-edge solutions for a digital future.
          </div>
          <button
            className="bg-[#061630] text-amber-200 font-semibold px-6 py-2 rounded-full hover:bg-amber-200 hover:text-black transition duration-300"
            onClick={() => navigate("/about")}
          >
            Learn More
          </button>
        </div>
      </div>
    </header>
  );
};

export default HeroSection;
