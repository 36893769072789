import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaTools, FaDollarSign, FaClock, FaIndustry } from 'react-icons/fa';  // Importing the icons

const ImageOne = () => {
  const navigate = useNavigate();

  const data = [
    {
      title: "Latest Milling Machinery",
      icon: <FaTools className="w-16 h-16 mb-3 text-white" />,  // Icon instead of image
    },
    {
      title: "Reasonable Rates",
      icon: <FaDollarSign className="w-16 h-16 mb-3 text-white" />,
    },
    {
      title: "Time Efficiency",
      icon: <FaClock className="w-16 h-16 mb-3 text-white" />,
    },
    {
      title: "Expertise in Industry",
      icon: <FaIndustry className="w-16 h-16 mb-3 text-white" />,
    },
  ];

  return (
    <section className="text-gray-700 body-font  mb-20">
     
      <div className="container mx-auto" style={{ width: '87%' }}> {/* Set the width to 80% */}
        <div className="flex flex-wrap text-center justify-center">
          {data.map((item, index) => (
            <div key={index} className="p-4 md:w-1/4 sm:w-1/2 ">
              <div className="px-4 py-6 transform transition duration-500 bg-[#061630]  shadow-md hover:shadow-lg rounded-lg">
                <div className="flex justify-center">
                  {item.icon}  {/* Render the icon here */}
                </div>
                <h2 className="title-font font-regular text-white">{item.title}</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ImageOne;
