import React from "react";

const AboutSection = () => {
  return (
    <div className="bg-gray-50 w-[86%] sm:flex items-center  mx-auto">
      <div className="sm:w-1/2 p-10">
        <div className="image object-center text-center">
          <img
            src="https://onedrive.live.com/embed?resid=38E489A2E9BE257B%2157390&authkey=%21ACfMiaygk_dA1nc&width=2000&height=2000"
            alt="About Us"
            className="w-full h-auto"
          />
        </div>
      </div>
      <div className="sm:w-1/2 p-5">
        <div className="text">
          <h2 className="my-4 font-semibold text-3xl sm:text-4xl text-gray-600">
            About <span className="text-[#061630]">Our Company</span>
          </h2>
          <p className="text-gray-700 text-justify text-lg leading-relaxed">
            Palmis Global was founded with the mission to share our expertise
            with companies, making a significant impact on improving our
            clients' bottom lines. This success inspired us to launch Palmis
            Global, extending our services to a global audience.
            <br /> <br />
            Since our inception, we have built businesses and systems, delivered
            tech solutions, acquired companies and real estate, and traded
            physical commodities across five continents.
            <br /> <br />
            With a strong focus on operational excellence and innovation, Palmis
            Global is rapidly expanding and diversifying. Our offices in the UK,
            Mauritius, UAE, Ghana, Togo, India, Bangladesh, and Pakistan reflect
            our commitment to growth, efficiency, and resource optimization on a
            global scale.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
