import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-gray-50 mt-20">
      <div className="max-w-screen-lg py-10 px-4 sm:px-6 text-gray-800 sm:flex justify-between mx-auto">
        <div className="p-5 sm:w-2/12 border-r">
          <div className="text-sm uppercase text-[#061630] font-bold">Menu</div>
          <ul>
            <li className="my-2">
              <Link className="hover:text-indigo-600" to="/">
                Home
              </Link>
            </li>
            <li className="my-2">
              <Link className="hover:text-indigo-600" to="/products">
                Products
              </Link>
            </li>
            <li className="my-2">
              <Link className="hover:text-indigo-600" to="/teams">
                Team
              </Link>
            </li>
            <li className="my-2">
              <Link className="hover:text-indigo-600" to="/career">
                Career
              </Link>
            </li>
            <li className="my-2">
              <Link className="hover:text-indigo-600" to="/corporategovernance">
                Corporate Governance
              </Link>
            </li>
            <li className="my-2">
              <Link className="hover:text-indigo-600" to="/partnership">
                PartnerShips
              </Link>
            </li>
            <li className="my-2">
              <Link className="hover:text-indigo-600" to="/contact">
                Contact
              </Link>
            </li>
            <li className="my-2">
              <Link className="hover:text-indigo-600" to="/about">
                About Us
              </Link>
            </li>
            <li className="my-2">
              <Link className="hover:text-indigo-600" to="/news">
                News
              </Link>
            </li>
          </ul>
        </div>
        <div className="p-5 sm:w-7/12 border-r text-center ">
          <h3 className="font-bold text-xl text-[#061630] mb-4">Palmis Global</h3>
          <p className="text-gray-500 text-sm mb-10">
          HQ - 86-90 Paul Street <br />
            London, England <br />
            United Kingdom <br />
            EC2A 4NE
          </p>
        </div>
        <div className="p-5 sm:w-3/12">
          <div className="text-sm uppercase text-[#061630] font-bold">
            Contact Us
          </div>
          <ul>
            <li className="my-2">
              
              +44 203 432 5672
             
            </li>
            <li className="my-2">
              
                contact@palmisglobal.com
            
            </li>
          </ul>
        </div>
      </div>
      <div className="flex py-5 m-auto text-gray-800 text-sm flex-col items-center border-t max-w-screen-xl">
       
        <div className="my-5">© 2024 Palmis Global. All Rights Reserved.</div>
      </div>
    </footer>
  );
};

export default Footer;
