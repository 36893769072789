import React, { useEffect } from 'react';

const BotpressChat = () => {
  useEffect(() => {
    // Load the Botpress inject script
    const injectScript = document.createElement('script');
    injectScript.src = 'https://cdn.botpress.cloud/webchat/v2/inject.js';
    injectScript.async = true;
    document.body.appendChild(injectScript);

    // Load the Botpress config script
    const configScript = document.createElement('script');
    configScript.src = 'https://mediafiles.botpress.cloud/e7da0a7b-d67f-4d5f-8b88-f295bfef5fe4/webchat/v2/config.js';
    configScript.async = true;
    document.body.appendChild(configScript);
    
    // Cleanup function to remove the scripts on component unmount
    return () => {
      document.body.removeChild(injectScript);
      document.body.removeChild(configScript);
    };
  }, []);

  return null;
};

export default BotpressChat;
