import React from 'react';


const TeamMember = ({ name, role, image}) => (
  <div className="text-center bg-white rounded-lg">
    <img className="w-full " src={image} alt={name} />
    <div className="p-6">
      <div className="text-lg">
        <p href="#" className="hover:text-indigo-500 text-gray-900 font-semibold transition duration-500 ease-in-out">
          {name}
        </p>
        <p className="text-gray-500 uppercase text-lg">{role}</p>
      </div>
     
    </div>
  </div>
);

const TeamSection = () => (
  <div className="bg-gray-50  mt-20  w-[86%] mx-auto">
    <div className="max-w-screen-lg mx-auto" >
      <div className="py-10 text-center ">
        <h3 className="text-3xl sm:text-4xl leading-normal font-semibold tracking-tight text-gray-600">
          MEET THE<span className="text-[#061630]"> TEAM</span>
        </h3>
      </div>
      <div className="grid grid-cols-3  py-14">
        <TeamMember
          name="Vikram Hurbungs"
          role="CEO"
          image="https://onedrive.live.com/embed?resid=38E489A2E9BE257B%2157387&authkey=%21AMVlteGt-zZA_Ak&width=701&height=1024"
     
        />
        <TeamMember
          name="Zaheer Hosany"
          role="COO"
          image="https://onedrive.live.com/embed?resid=38E489A2E9BE257B%2157388&authkey=%21ABKWHmuwNEdVxBU&width=375&height=300"
     
        />
        <TeamMember
          name="Saud Khan"
          role="CTO"
          image="https://onedrive.live.com/embed?resid=38E489A2E9BE257B%2157389&authkey=%21AB3rEvgbb6Lof0M&width=2194&height=1755"
       
        />
      </div>
    </div>
  </div>
);

export default TeamSection;
