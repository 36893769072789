import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const LocationDisplay = () => {
  // Define your address and contact information
  const address = '86-90 Paul Street, London, England, EC2A 4NE';
  const phoneNumbers = [
    '+44 770 7474 444', // W/A Num
    '+44 203 432 5672'  // Office
  ];

  // Coordinates for the location (replace with your actual coordinates)
  const position = [51.5236, -0.0856]; // Example coordinates

  // Google Maps URL for general view
  const googleMapsUrl = 'https://maps.app.goo.gl/6VNGYtJFBw49QD1o7';

  return (
    <section className="bg-gray-50 w-[86%] mx-auto">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
        <div className="max-w-2xl lg:max-w-4xl mx-auto text-center">
          <h2 className="text-center mb-4 text-3xl font-semibold leading-none tracking-tight text-gray-700">VISIT OUR LOCATION</h2>
          <p className="mt-4 text-lg text-gray-500">
          We operate across a global network.
          Our business brings together an international team based in different locations worldwide and a range of operational
          </p>
        </div>
        <div className="mt-16 lg:mt-20">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="rounded-lg overflow-hidden">
              <div style={{ height: '480px', position: 'relative' }}>
                <MapContainer center={position} zoom={16} style={{ zIndex: '1', height: '100%', width: '100%' }}>
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker position={position}>
                    <Popup>{address}</Popup>
                  </Marker>
                </MapContainer>
              </div>
            </div>
            <div>
              <div className="max-w-full mx-auto rounded-lg overflow-hidden">
                <div className="px-6 py-4">
                  <h3 className="text-lg font-medium text-gray-900">Our Address</h3>
                  <p className="mt-1 text-gray-600">{address}</p>
                </div>
                <div className="border-t border-gray-200 px-6 py-4">
                  <h3 className="text-lg font-medium text-gray-900">Hours</h3>
                  <p className="mt-1 text-gray-600">Monday - Friday: 9am - 5pm</p>
                  <p className="mt-1 text-gray-600">Saturday: 10am - 4pm</p>
                  <p className="mt-1 text-gray-600">Sunday: Closed</p>
                </div>
                <div className="border-t border-gray-200 px-6 py-4">
                  <h3 className="text-lg font-medium text-gray-900">Contact</h3>
                  <p className="mt-1 text-gray-600">Email: info@example.com</p>
                  <p className="mt-1 text-gray-600">Phone: {phoneNumbers[0]}</p>
                  <p className="mt-1 text-gray-600">Office: {phoneNumbers[1]}</p>
                </div>
                <div className="border-t border-gray-200 px-6 py-4 text-center">
                  <a
                    href={googleMapsUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-block px-6 py-3 mt-4 text-base font-medium text-white bg-[#061630] rounded-lg hover:bg-amber-200 hover:text-blue-900"
                  >
                    Visit Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LocationDisplay;
