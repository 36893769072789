import React, { useEffect } from "react";
import { motion } from "framer-motion";

const Career = () => {
  useEffect(() => {
    // Scroll to the top when the page loads
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }} // Initial motion from bottom
      animate={{ opacity: 1, y: 0 }} // Animation to top
      transition={{ duration: 0.5, delay: 0.2 }} // Smooth transition
      className="pt-20 min-h-screen flex flex-col p-8 sm:p-16 md:p-24 justify-center bg-white"
    >
      <div data-theme="teal" className="mx-auto max-w-6xl">
        <h2 className="sr-only">Featured case study</h2>
        <section className="font-sans text-black">
          <div className="[ lg:flex lg:items-center ] [ fancy-corners fancy-corners--large fancy-corners--top-left fancy-corners--bottom-right ]">
            <div className="flex-shrink-0 self-stretch sm:flex-basis-40 md:flex-basis-50 xl:flex-basis-60">
              <div className="h-full">
                <article className="h-full">
                  <div className="h-full">
                    <img
                      className="h-full object-cover"
                      src="https://onedrive.live.com/embed?resid=38E489A2E9BE257B%2157264&authkey=%21AELPyjzyTb5x5yg&width=1024"
                      width="733"
                      height="412"
                      alt=""
                    />
                  </div>
                </article>
              </div>
            </div>
            <div className="p-6 bg-gray-100 font-gray-600">
              <div className="leading-relaxed">
                <h2 className="leading-tight text-4xl font-bold">
                  NO VACANCIES at the moment
                </h2>
                <p className="mt-4">
                  Are you looking to embark on an exciting career journey in the
                  dynamic world of global trading? How about joining our Real
                  Estate team? Maybe you’re a genius at Mergers and
                  Acquisitions?
                  <br />
                  <br />
                  At Palmis Global, we're always on the lookout for talented
                  individuals who are passionate about making a difference.
                </p>
                <p className="mt-4">
                  If you're ready to take the next step in your career and join
                  a team dedicated to excellence, we invite you to submit your
                  CV and cover letter to Info@palmisglobal.com
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </motion.div>
  );
};

export default Career;
