import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

const CookieConsent = ({ onAccept, onReject }) => {
  const [cookies] = useCookies(["userConsent"]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    

    if (!cookies.userConsent) {
      setIsVisible(true);
    } else {
    }
  }, [cookies]);

  const handleAccept = () => {
    onAccept();
    setIsVisible(false);
  };

  const handleReject = () => {
    onReject();
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }


  return (
    <section className="fixed max-w-xs sm:max-w-sm p-3 mx-auto bg-white border border-gray-200 dark:bg-gray-800 left-2 bottom-2 sm:left-4 sm:bottom-4 dark:border-gray-700 rounded-lg shadow-lg">
      <h2 className="font-semibold text-xs sm:text-sm text-gray-800 dark:text-white">🍪 Cookie Notice</h2>
      <p className="mt-1 text-xs sm:text-sm text-gray-600 dark:text-gray-300">
        We use cookies to ensure the best experience on our website.{" "}
        <a href="#" className="text-blue-500 hover:underline">Read cookies policies</a>.
      </p>
      <div className="flex items-center justify-between mt-2 gap-x-1 sm:gap-x-2">
        <button
          className="text-xs text-gray-800 underline dark:text-white dark:hover:text-gray-400 hover:text-gray-600 focus:outline-none"
          onClick={handleReject}
        >
          Manage preferences
        </button>
        <button
          className="text-xs bg-gray-900 font-medium rounded-lg hover:bg-gray-700 text-white px-2 py-1 sm:px-3 sm:py-1.5 duration-300 transition-colors focus:outline-none"
          onClick={handleAccept}
        >
          Accept
        </button>
      </div>
    </section>
  );
};

export default CookieConsent;
