import React from "react";
import { useNavigate } from "react-router-dom";
import { FaCogs, FaMobileAlt, FaLaptop, FaCode, FaChrome, FaServer, FaEdit } from 'react-icons/fa';

export default function CardHorizontal() {
  const navigate = useNavigate();

  
  return (
    <div className="max-w-3xl mx-auto px-5 mt-8">
        <div className="text-center">
            <h2 className="font-semibold text-3xl text-gray-600">Features you'll love</h2>
            <p className="max-w-md mx-auto mt-2 text-gray-500">
                A responsive documentation template built for everyone who wants to create a plugin.
            </p>
        </div>

        <div className="grid md:grid-cols-2 gap-10 mt-10">
            <div className="flex gap-4 items-start">
                <span className="text-blue-800 bg-blue-600/10 p-3 rounded-full">
                    <FaCogs className="w-5 h-5" />
                </span>
                <div>
                    <h3 className="font-semibold text-xl">Customizable</h3>
                    <p className="mt-1 text-gray-500">
                        You don't need to be an expert to customize this plugin. Our code is very readable and well documented.
                    </p>
                </div>
            </div>

            <div className="flex gap-4 items-start">
                <span className="text-blue-800 bg-blue-600/10 p-3 rounded-full">
                    <FaMobileAlt className="w-5 h-5" />
                </span>
                <div>
                    <h3 className="font-semibold text-xl">Fully Responsive</h3>
                    <p className="mt-1 text-gray-500">
                        With mobile, tablet & desktop support it doesn't matter what device you're using. This plugin is responsive in all browsers.
                    </p>
                </div>
            </div>

            <div className="flex gap-4 items-start">
                <span className="text-blue-800 bg-blue-600/10 p-3 rounded-full">
                    <FaLaptop className="w-5 h-5" />
                </span>
                <div>
                    <h3 className="font-semibold text-xl">Developer Support</h3>
                    <p className="mt-1 text-gray-500">
                        Our plugins are supported by sponsors who provide community support. Sponsors will get email support on weekdays.
                    </p>
                </div>
            </div>

            <div className="flex gap-4 items-start">
                <span className="text-blue-800 bg-blue-600/10 p-3 rounded-full">
                    <FaCode className="w-5 h-5" />
                </span>
                <div>
                    <h3 className="font-semibold text-xl">Clean Code</h3>
                    <p className="mt-1 text-gray-500">
                        We strictly follow a set of guidelines along with unit tests to make sure your implementation as easy as possible.
                    </p>
                </div>
            </div>

            <div className="flex gap-4 items-start">
                <span className="text-blue-800 bg-blue-600/10 p-3 rounded-full">
                    <FaChrome className="w-5 h-5" />
                </span>
                <div>
                    <h3 className="font-semibold text-xl">Cross Browser</h3>
                    <p className="mt-1 text-gray-500">
                        We make sure our plugins are working perfectly with all modern browsers available such as Chrome, Firefox, Safari.
                    </p>
                </div>
            </div>

            <div className="flex gap-4 items-start">
                <span className="text-blue-800 bg-blue-600/10 p-3 rounded-full">
                    <FaServer className="w-5 h-5" />
                </span>
                <div>
                    <h3 className="font-semibold text-xl">Well Maintained</h3>
                    <p className="mt-1 text-gray-500">
                        This template is actively maintained by the core plugin team. We are working on fixing each of the issues reported.
                    </p>
                </div>
            </div>
        </div>
    </div>
);
}
