import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

const teamMembers = [
  {
    name: "Vikram Hurbungs",
    position: "CEO",
    imageUrl: "https://onedrive.live.com/embed?resid=38E489A2E9BE257B%2157387&authkey=%21AMVlteGt-zZA_Ak&width=701&height=1024",
    description: `Vikram Hurbungs is the visionary CEO of Palmis Global. Under his leadership, the company has embarked on a journey of strategic expansion and diversification across various industries, setting the stage for a new era of growth and innovation. With an unwavering commitment to operational excellence and a disciplined approach to business, Vikram has positioned Palmis Global as a beacon of efficiency and success.`
  },
  {
    name: "Zaheer Hosany",
    position: "COO",
    imageUrl: "https://onedrive.live.com/embed?resid=38E489A2E9BE257B%2157388&authkey=%21ABKWHmuwNEdVxBU&width=375&height=300",
    description: `Zaheer Hosany, the COO of Palmis Global, plays a crucial role in driving the company’s global trade operations. His extensive industry experience has been instrumental in fostering strategic partnerships and facilitating the delivery of commodities to diverse markets. Zaheer’s leadership is key to maintaining Palmis Global's reputation as a key player in international business operations.`
  },
  {
    name: "Saud Khan",
    position: "CTO",
    imageUrl: "https://onedrive.live.com/embed?resid=38E489A2E9BE257B%2157389&authkey=%21AB3rEvgbb6Lof0M&width=2194&height=1755",
    description: `As the CTO, Saud Khan is at the forefront of Palmis Global’s technological advancements. His dedication to leveraging technology to drive growth and innovation has been pivotal in the company’s success. Saud’s expertise ensures that Palmis Global remains at the cutting edge of technological innovation, driving the company forward in the global marketplace.`
  }
];

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } },
};

const TeamComponent = () => {
  useEffect(() => {
    // Scroll to top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      className="bg-white dark:bg-gray-50 mt-28 w-[86%] mx-auto"
    >
      <div className="h-[32rem] dark:bg-gray-50">
        <div className="container px-6 py-10 mx-auto">
          <h1 className="text-2xl font-semibold text-center text-gray-800 capitalize lg:text-3xl dark:text-white">
            Meet The Team
          </h1>

          <div className="flex justify-center mx-auto mt-6">
            <span className="inline-block w-40 h-1 bg-blue-800 rounded-full"></span>
            <span className="inline-block w-3 h-1 mx-1 bg-blue-800 rounded-full"></span>
            <span className="inline-block w-1 h-1 bg-blue-800 rounded-full"></span>
          </div>

          <p className="max-w-2xl mx-auto mt-6 text-center text-gray-500 dark:text-gray-300">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo incidunt ex placeat modi magni quia error alias, adipisci rem similique, at omnis eligendi optio eos harum.
          </p>
        </div>
      </div>

      <div className="container px-6 py-10 mx-auto -mt-72 sm:-mt-80 md:-mt-96">
        <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-16 md:grid-cols-2 xl:grid-cols-3">
          {teamMembers.map((member, index) => (
            <motion.div
              key={index}
              className="flex flex-col items-center p-4 border sm:p-6 rounded-xl dark:border-gray-700 shadow-md"
              variants={cardVariants}
            >
              <img
                className="object-cover w-full rounded-xl aspect-square"
                src={member.imageUrl}
                alt={member.name}
              />
              <h1 className="mt-4 text-2xl font-semibold text-gray-700 capitalize dark:text-white">
                {member.name}
              </h1>
              <h2 className="mt-2 text-gray-600 text-2xl font-bold">
                {member.position}
              </h2>
              <p className="text-justify text-lg leading-relaxed mt-2 text-gray-600 ">
                {member.description}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default TeamComponent;
