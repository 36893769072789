import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { initGA,trackPageView } from "./cookies/googleanalytics";
import { useCookies } from "react-cookie";
import CookieConsent from "./cookies/cookieconsent";
import Header from "./home/header/header";
import Footer from "./home/footer/footer";
import BotpressChat from "./home/botpresschat";
import Home from "./home/home";
import AboutUs from "./AboutUs/AboutUs";
import DataPage from "./Products/Datapage";
import ProductPage from "./Products/productpage";
import SpecificProduct from "./Products/specificproduct";
import Governance from "./governance/governance";
import Career from "./career/career";
import PartnerShip from "./partnership/partnership";
import TeamComponent from "./team/teamcomponent";
import Contact from "./contactus/contact";
import NewsComponent from "./news/newscomponent";

function App() {
  const location = useLocation();
  const [cookies, setCookie] = useCookies(["userConsent"]);

  // Initialize GA and track page views only if cookies are accepted
  useEffect(() => {
    if (cookies.userConsent === "accepted") {
      initGA(); // Initialize GA
      trackPageView(location.pathname); // Track page views
    }
  }, [cookies, location]);

  const handleAcceptCookies = () => {
    setCookie("userConsent", "accepted", { path: "/" });
    initGA(); // Initialize GA when cookies accepted
  };

  const handleRejectCookies = () => {
    setCookie("userConsent", "rejected", { path: "/" });
  };

  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/products" element={<ProductPage />} />
        <Route path="/teams" element={<TeamComponent />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/corporategovernance" element={<Governance />} />
        <Route path="/data/:categoryName" element={<DataPage />} />
        <Route path="/details/:productName" element={<SpecificProduct />} />
        <Route path="/news" element={<NewsComponent />} />
        <Route path="/partnership" element={<PartnerShip />} />
      </Routes>
      <BotpressChat />
      <Footer />
      <CookieConsent onAccept={handleAcceptCookies} onReject={handleRejectCookies} />
    </div>
  );
}

export default App;