import React from "react";
import { useNavigate } from "react-router-dom";

const HeadlineCards = () => {
  const navigate = useNavigate();

  return (
    <div className="relative py-10 flex flex-col md:flex-row items-center justify-between bg-gray-50 w-[86%] mx-auto mb-20">
      
      {/* Image */}
      <div className="mr-8 w-full md:w-1/2 order-1 md:order-2 mx-auto rounded-lg">
        <img
          className="h-64 w-full md:h-96 object-cover rounded-lg px-4 "
          src="https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?q=80&w=3869&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="Random image"
        />
      </div>

      {/* Text */}
      <div className="relative w-full md:w-1/2 px-8 order-2 md:order-1 mt-6 md:mt-0">
        <div className="flex flex-col items-center">
          <p className="text-xl md:text-2xl font-regular mb-8">
            Meet Palmis Global employees from across the globe
          </p>
          <button 
            onClick={() => navigate("/teams")} 
            className="px-6 py-3 bg-[#061630] text-white text-lg font-semibold rounded-md shadow-lg hover:bg-amber-200 hover:text-blue-900 transition duration-300 "
          >
            Meet Us
          </button>
        </div>
      </div>
      
    </div>
  );
};

export default HeadlineCards;
