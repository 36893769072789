import React from 'react';
import productData from '../../data.json'
import { Link } from 'react-router-dom';

const CardSection = () => {
  return (
    <div className="relative px-6 pt-15 pb-28 lg:px-8 lg:pt-18 lg:pb-32">
      <div className="absolute inset-0">
        <div className="h-1/3 bg-white sm:h-2/3"></div>
      </div>
      <div className="relative mx-auto max-w-7xl">
        <div className="text-center">
          <h2 className="text-3xl font-semibold text-gray-600 sm:text-3xl">Our Products</h2>
          <p className="mx-auto mt-3 max-w-2xl  text-gray-500 sm:mt-4">
            We PALMIS GLOBAL Active in Four Business Pillars
          </p>
        </div>
        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-4">
          {productData.categories.map((card, index) => (
            <Link key={index} to={`/data/${card.title}`} >
              <div 
                key={index} 
                className="flex flex-col overflow-hidden rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-xl"
              >
                <div className="flex-shrink-0">
                  <img className="h-48 w-full object-cover" src={card.imageSrc} alt={card.title} />
                </div>
                <div className="flex flex-1 flex-col p-6 bg-white">
                  <div className="flex-1">
                    <h3 className="text-lg font-semibold text-gray-900">{card.title}</h3>
                    <p className="mt-3 text-sm text-gray-500">{card.description}</p>
                  </div>
                  <div className="mt-6">
                    <Link to={`/data/${card.title}`} className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-[#061630] rounded-full hover:bg-amber-200 hover:text-blue-900">
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardSection;
