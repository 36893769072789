import React, { useEffect } from "react";
import { motion } from "framer-motion";

const NewsComponent = () => {
  const newsLinks = [
    {
      url: "https://www.abnewswire.com/pressreleases/palmis-global-celebrates-strategic-expansion-across-industries-anchored-in-efficiency-and-innovation_693691.html",
      title: "Palmis Global Celebrates Strategic Expansion Across Industries",
      description:
        "Palmis Global's recent announcement underscores their dedication to enhancing efficiency across various industries. The company continues to innovate and adapt to emerging markets.",
    },
    {
      url: "https://www.newschannelnebraska.com/story/50641816/palmis-global-celebrates-strategic-expansion-across-industries-anchored-in-efficiency-and-innovation",
      title: "Palmis Global: Expansion and Innovation Across Industries",
    },
    {
      url: "https://www.wicz.com/story/50641816/palmis-global-celebrates-strategic-expansion-across-industries-anchored-in-efficiency-and-innovation",
      title: "Palmis Global: Innovation ",
    },
  ];

  // Scroll to the top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.section
      className="mt-24 bg-white dark:bg-gray-900 w-[86%] mx-auto"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="container px-6 py-10 mx-auto">
        <div className="lg:flex lg:-mx-6">
          {/* Main News Article */}
          <div className="lg:w-3/4 lg:px-6">
            <motion.img
              className="object-cover object-center w-full h-80 xl:h-[28rem] rounded-xl"
              src="https://onedrive.live.com/embed?resid=38E489A2E9BE257B%2157398&authkey=%21ADYbxmo3jm2IHoM&width=640&height=427"
              alt={newsLinks[0].title}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1 }}
            />
            <motion.div
              className="mt-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.5 }}
            >
              <p className="text-sm text-blue-500 uppercase">WANT TO KNOW</p>
              <h1 className="mt-4 text-2xl font-semibold leading-tight text-gray-800 dark:text-white">
                PALMIS GLOBAL all over the world
              </h1>
              <p className="mt-3 text-sm text-gray-500 dark:text-gray-300">
                {newsLinks[0].description}
              </p>
            </motion.div>
          </div>

          {/* Sidebar with Links */}
          <motion.div
            className="mt-8 lg:w-1/4 lg:mt-0 lg:px-6"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <div>
              <h3 className="text-blue-500 capitalize">Recent News</h3>
              {newsLinks.map((news, index) => (
                <div key={index} className="mt-4">
                  <a
                    href={news.url}
                    className="block font-medium text-gray-700 hover:underline hover:text-gray-500 dark:text-gray-400"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {news.title}
                  </a>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default NewsComponent;
