import React, {  useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import HeroSection from "./hero/herosection";
import CardSection from "./cardsection/cardsection";
import ImageOne from "./image/imageone";
import CardHorizontal from "./cardsection/cardhorizontal";
import TeamSection from "../team/teamsection";
import LocationDisplay from "../location/locationdisplay";
import AboutSection from "../AboutUs/aboutsection";
import ContactUs from "../contactus/contactus";

const Home = () => {
  const controls = useAnimation();
  const [refHero, inViewHero] = useInView();
  const [refCard, inViewCard] = useInView();
  const [refImage, inViewImage] = useInView();
  const [refHorizontal, inViewHorizontal] = useInView();
  const [refTeam, inViewTeam] = useInView();
  const [refContact, inViewContact] = useInView();



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (inViewHero) {
      controls.start({ opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } });
    }
  }, [controls, inViewHero]);

  return (
    <div className="relative bg-white">
     
      <motion.div ref={refHero} animate={controls} initial={{ opacity: 0, y: -50 }}>
        <HeroSection />
      </motion.div>

      <motion.div
        ref={refImage}
        animate={inViewImage ? { opacity: 1, y: 0 } : { opacity: 0, y: -50 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <ImageOne />
      </motion.div>

      <motion.div
        ref={refCard}
        animate={inViewCard ? { opacity: 1, y: 0 } : { opacity: 0, y: -50 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <CardSection />
      </motion.div>

      <motion.div
        ref={refHorizontal}
        animate={inViewHorizontal ? { opacity: 1, y: 0 } : { opacity: 0, y: -50 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <CardHorizontal />
      </motion.div>

      <motion.div
        ref={refTeam}
        animate={inViewTeam ? { opacity: 1, y: 0 } : { opacity: 0, y: -50 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <TeamSection />
        <AboutSection />
        <LocationDisplay />
      </motion.div>

      <motion.div
        ref={refContact}
        animate={inViewContact ? { opacity: 1, y: 0 } : { opacity: 0, y: -50 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
       <ContactUs/>
      </motion.div>
    </div>
  );
};

export default Home;
