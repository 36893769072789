import React from 'react';

const GovImage = () => {
  return (
    <div className="py-20 bg-white grid min-h-[140px] w-full place-items-center  rounded-lg ">
      <img
        className="object-cover object-center w-1/2 max-w-none h-full"
        src="https://onedrive.live.com/embed?resid=38E489A2E9BE257B%2157403&authkey=%21AE_RoCvS_7iwiSs&height=1024"
        alt="palmis global"
      />
    </div>
  );
};

export default GovImage;
