const Main = () => {
  return (
    <div className="py-16 px-6 md:px-16 bg-white mt-12 w-[86%] mx-auto">
      <div className="container mx-auto text-gray-600">
        <h1 className="text-2xl md:text-2xl lg:text-3xl font-bold  mb-8">
          WHY PALMIS GLOBAL?
        </h1>
        <div className="md:flex md:space-x-8 lg:space-x-12">
          
          <div className="md:w-7/12 lg:w-7/12">
            <p className="text-justify text-lg leading-relaxed md:text-xl lg:text-lg lg:leading-relaxed">
              Palmis Global has firmly established itself as a dynamic and
              forward-thinking entity in the global business arena. The
              company's journey of strategic expansion and diversification
              across various industries marks a significant milestone, setting
              the stage for a new era of growth and innovation. With an
              unwavering commitment to operational excellence and a disciplined
              approach to business, Palmis Global has become a beacon of
              efficiency and success.
            </p>
            <p className="mt-6 text-justify text-lg leading-relaxed md:text-xl lg:text-lg lg:leading-relaxed">
              Palmis Global also plays a significant role in global trade,
              providing trade financing solutions and facilitating the delivery
              of commodities to diverse markets. Leveraging extensive industry
              experience, the company fosters strategic partnerships,
              strengthening its position as a key player in international
              business operations.
            </p>
            <p className="mt-6 text-justify text-lg leading-relaxed md:text-xl lg:text-lg lg:leading-relaxed">
              This initiative underscores the company’s dedication to leveraging
              technology to drive growth and innovation.
            </p>
          </div>
          <div className="md:w-5/12 lg:w-5/12 flex justify-center md:justify-start mb-6 md:mb-0">
            <img
              src="https://onedrive.live.com/embed?resid=38E489A2E9BE257B%2157401&authkey=%21AChMrXDiijdPPRY&width=640&height=427"
              alt=""
              loading="lazy"
              className="rounded-lg shadow-lg w-full object-cover h-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Main;
