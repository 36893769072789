import ContactUs from "./contactus";
import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";


const Contact =()=>{
    const controls = useAnimation();

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    
        // Start animation for the component
        controls.start({ opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } });
      }, [controls]);
return(
<div className="mt-28">     <motion.div animate={controls} initial={{ opacity: 0, y: -50 }} >
      <ContactUs />
    </motion.div>

</div>
);


}
export default Contact;